import { api } from 'lib/api';
import { UrlBuilder } from 'utils/helpers/UrlBuilder';

import type { TChangePasswordResponse, TGetUserInfoResponse } from './types';

const urlBuilder = new UrlBuilder('api/v1');

export const userApi = {
    getUserInfo: async () => {
        const response = await api.get<TGetUserInfoResponse>(
            urlBuilder.build('user/info'),
        );

        return response.data;
    },

    logout: async () => {
        const logoutUrl = urlBuilder.build('logout');

        try {
            const response = await fetch(logoutUrl, {
                method: 'POST',
                redirect: 'follow',
                credentials: 'include',
            });

            if (response.redirected) {
                window.location.replace(response.url);

                return;
            }

            if (!response.ok || response.status.toString().startsWith('4')) {
                throw await response.json();
            }
        } catch (e) {
            console.log(e, 'catch error');
        }
    },

    changePassword: async () => {
        const response = await api.get<TChangePasswordResponse>(
            urlBuilder.build('user/change-password'),
        );

        return response.data;
    },
};
