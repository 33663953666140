import * as Sentry from '@sentry/react';

const ENVIRONMENT = process.env.VITE_SENTRY_ENVIRONMENT;
const IS_LOCAL = ENVIRONMENT === 'local';
const IS_ENV_EXIST = !!ENVIRONMENT;

export const initSentry = () => {
    Sentry.init({
        dsn: 'https://f76ce021d796897471d756ba3d471b9d@sentry2.dats.tech/77',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.captureConsoleIntegration({
                levels: ['warn', 'error'],
            }),
        ],
        // Tracing
        tracesSampleRate: 1.0,
        environment: ENVIRONMENT,
        // Session Replay
        replaysSessionSampleRate: IS_LOCAL ? 1 : 0.1,
        replaysOnErrorSampleRate: 1.0,
        enabled: IS_ENV_EXIST && !IS_LOCAL,
    });
};
