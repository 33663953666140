import 'antd/dist/reset.css';

import React, { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { THEME_CONFIG } from 'utils/constants/theme';

import PageLoader from 'components/ui/PageLoader/PageLoader';
import { useGetCurrentAntdLocale } from 'components/utils/hooks/useGetCurrentAntdLocale';

import router from './router';

const App: React.FC = () => {
    const locale = useGetCurrentAntdLocale();

    return (
        <Suspense fallback={<PageLoader />}>
            <ConfigProvider locale={locale} theme={THEME_CONFIG}>
                <RouterProvider router={router} />
            </ConfigProvider>
        </Suspense>
    );
};

export default App;
