import type { Locale } from 'antd/es/locale';
import enUS from 'antd/es/locale/en_US';
import ruRU from 'antd/es/locale/ru_RU';

export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 992;

const LOCALES_ANTD_MAP: Record<string, Locale> = {
    ru: ruRU,
    en: enUS,
};

export const uiConstants = {
    LOCALES_ANTD_MAP,
};
