import type { AxiosInstance } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import { commonConstants } from 'utils/constants/common';
import { AUTH_COOKIE_NAME } from 'utils/constants/cookies';

import { handleError } from './errorHandlers';

const getInstanceApi = (): AxiosInstance => {
    const AUTH_COOKIE = process.env.APP_COOKIE_SESSION;

    if (AUTH_COOKIE) {
        Cookies.set(AUTH_COOKIE_NAME, AUTH_COOKIE);
    }

    const api = axios.create({
        timeout: 20000,
        withCredentials: true,
        headers: {
            'X-Client-Version': commonConstants.APP_CLIENT_VERSION,
        },
    });

    api.interceptors.response.use((response) => response, handleError());

    return api;
};

export const api = getInstanceApi();
