import {
    formatIncompletePhoneNumber,
    getCountryCallingCode,
} from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js/types';
import { uiConstants } from 'utils/constants/ui';
import type { TOptions } from 'utils/types/select';

const sleep = <TValue = void>(ms: number, value?: TValue): Promise<TValue> =>
    new Promise((r) => setTimeout(() => r(value as TValue), ms));

const copyToClipboard = async (text: string): Promise<boolean> => {
    if (!navigator.clipboard) {
        console.error('Dont use navigator.clipboard');
        return false;
    }

    try {
        await navigator.clipboard.writeText(text);
        return true;
    } catch (err) {
        console.error('Async: Could not copy text:', err);
        return false;
    }
};

const formatPhoneNumber = (
    phoneNumber: string,
    countryCode: CountryCode,
): string => {
    const sanitizedValue = phoneNumber.replace(/\D/g, '');
    const formattedValue = formatIncompletePhoneNumber(
        sanitizedValue,
        countryCode,
    );
    return `${getCountryCallingCode(countryCode)}${formattedValue}`;
};

const formatArrayToOptions = (data: string[]): TOptions => {
    if (!data.length) {
        return [];
    }

    return data.map((name: string) => ({ value: name, label: name }));
};

const getSelectValues = <T extends unknown>(options: TOptions<T>) => {
    return options.map((option) => option.value);
};

const getBrowserLocale = () => {
    const localeMap = uiConstants.LOCALES_ANTD_MAP;
    const defaultLocale = 'en-US';
    const browserLocale = navigator.language || defaultLocale;

    return localeMap[browserLocale] || localeMap[defaultLocale];
};

export const uiHelpers = {
    sleep,
    copyToClipboard,
    formatPhoneNumber,
    formatArrayToOptions,
    getSelectValues,
    getBrowserLocale,
};
